import MailToButton from "../common/mailToButton";
import { AiOutlineMail, AiFillPhone } from "react-icons/ai";

const ContactInformation = ({ email, phone = "" }) => {
  return (
    <div className="text-center pt-4">
      <div className="py-2">
        <AiOutlineMail className="small-icon"> </AiOutlineMail>{" "}
        <MailToButton mailTo={email}> </MailToButton>
      </div>
      {phone && (
        <div className="py-2">
          <AiFillPhone className="small-icon" />{" "}
          <a className="link text-center" href={"tel:" + phone}>
            {phone}
          </a>
        </div>
      )}
    </div>
  );
};

export default ContactInformation;
