import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchNewsData } from "../redux/thunks/newsThunk";
import useNews from "../hooks/useNews";
import CardNews from "../components/news/cardNews";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
const News = () => {
  const dispatch = useDispatch();
  const { loading, newsData, error } = useNews();
  const [maxChars, setMaxChars] = useState(250); // Default value for desktop

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = newsData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleResize = () => {
    const newMaxChars = window.innerWidth < 768 ? 125 : 300; // Adjust the breakpoint (768) as needed
    setMaxChars(newMaxChars);
  };

  useEffect(() => {
    document.title = "RUI - Noticias";
    window.scrollTo(0, 0);
    handleResize();
    window.addEventListener("resize", handleResize);
    getNews();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getNews = async () => {
    await dispatch(fetchNewsData());
  };

  const sortedNewsData =
    newsData && newsData.length > 0
      ? [...newsData].sort((a, b) => new Date(b.date) - new Date(a.date))
      : [];

  return (
    <section className=" pt-5">
      <section className="news-section d-flex d-flex--center">
        <p className="secondary-title-text text-center pt-5 py-4">
          Notas de Prensa
        </p>
      </section>

      <div className="">
        <p className="title-text text-center pt-5 py-4">Noticias</p>
        <div className="d-flex align-items-sm-center justify-content-center">
          {newsData.length > 0 && (
            <ul className="pagination m-auto">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  aria-label="Pagina anterior"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaArrowAltCircleLeft />
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    aria-label="Pagina actual"
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  aria-label="Pagina siguiente"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FaArrowAltCircleRight />
                </button>
              </li>
            </ul>
          )}
        </div>
        <div className=" py-5">
          <div className="row px-5">
            {Array.isArray(sortedNewsData) &&
              sortedNewsData.slice(startIndex, endIndex).map((item, index) => (
                <div
                  className="col-md-12 col-lg-12 col-xl-4 col-12 col-sm-12 d-flex align-items-center justify-content-center py-4 px-md-4"
                  key={index}
                >
                  <CardNews
                    id={item.id}
                    title={item.title}
                    content={item.description}
                    date={item.date}
                    maxChars={maxChars}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
