import { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.webp";
import ContactInformation from "../contact/contactInformation";
import List from "./list";
import SocialMedia from "./socialMedia";

const Footer = ({ elements, paths }) => {
  const footer = useRef();
  const copyright = useRef();
  const documentScroll = () => {
    var scrollMaxY =
      window.scrollMaxY ||
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
    footer.current?.classList.toggle(
      "footer--scroll",
      window.scrollY >= scrollMaxY - 512
    );
    copyright.current?.classList.toggle(
      "footer--copyright-scroll",
      window.scrollY >= scrollMaxY - 512
    );
  };

  document.addEventListener("scroll", documentScroll);

  return (
    <footer className="footer" ref={footer}>
      <div className="container pb-3">
        <div className="row">
          <div className="col-4 d-flex d-flex--center d-flex--direction-column">
            <div className="row">
              <Link
                className="d-flex d-flex--center font-size-xs"
                to="/"
                aria-label="Logo Pie de Pagina"
              >
                <img
                  src={logo}
                  alt="Logo"
                  className="footer__logo"
                  width="100%"
                  height="100%"
                />
              </Link>
            </div>
          </div>
          <div className="col-4 d-flex d--flex-center d-flex--direction-column text-center">
            <List elements={elements} paths={paths}></List>
          </div>
          <div className="col-4">
            <SocialMedia />
            <ContactInformation
              email={"rui.koica@gmail.com "}
              phone={"+51 976 774 367"}
            />
          </div>
        </div>
      </div>

      <div ref={copyright} className="pt-4 pb-4">
        <p className="text-center secondary-paragraph-text">
          © {new Date().getFullYear()} - Red Universitaria de Innovación. Todos
          los derechos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
